import { Input, InputAdornment } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "./form.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RoomIcon from "@mui/icons-material/Room";
import emailjs from "@emailjs/browser";
import DateRangeComp from "./DateRangeComp";
import Grid from "@mui/material/Grid";

import PhoneIcon from "@mui/icons-material/Phone";
import { MdOutlineLocalAirport } from "react-icons/md";
import SuccessModal from "../markup/components/SuccessModal";

const FlightForm = ({ styling }) => {
  const [autoCompleteFrom, setAutoCompleteFrom] = useState(false);
  const [autoCompleteTo, setAutoCompleteTo] = useState(false);
  const [value, setValue] = useState("");
  const [valueTo, setValueTo] = useState("");
  const [dateField, setDateField] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [validationTo, setValidationTo] = useState(false);
  const [validationFrom, setValidationFrom] = useState(false);
  const [validationDate, setValidationDate] = useState(false);
  const [validationPhone, setValidationPhone] = useState(false);
  const [selectTripWay, setSelectTripWay] = useState("Round Trip");
  const [open, setOpen] = useState(false);

  const [flightAdult, setFlightAdult] = useState(1);
  const [flightChild, setFlightChild] = useState(0);
  const [flightInfant, setFlightInfant] = useState(0);
  const [successOpen, setSuccessOpen] = React.useState(false);

  const handleClose = () => {
    setSuccessOpen(false);
  };
  const flightPassengerInc = (value) => {
    if (value === "adult") {
      setFlightAdult(flightAdult + 1);
    } else if (value === "child") {
      setFlightChild(flightChild + 1);
    } else {
      setFlightInfant(flightInfant + 1);
    }
  };

  const flightPassengerDec = (value) => {
    if (value === "adult") {
      if (flightAdult > 1) return setFlightAdult(flightAdult - 1);
    } else if (value === "child") {
      if (flightChild > 0) return setFlightChild(flightChild - 1);
    } else {
      if (flightInfant > 0) return setFlightInfant(flightInfant - 1);
    }
  };

  const form = useRef();
  const refOne = useRef(null);
  const handlePhone = (e) => {
    setPhoneNo(e.target.value);
  };
  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setAutoCompleteFrom(false);
      setAutoCompleteTo(false);
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const sendEmail = (e) => {
    // e.preventDefault();
    setValidationTo(value === "");
    setValidationFrom(valueTo === "");
    setValidationDate(dateField === "");
    setValidationPhone(phoneNo === "");

    if (value !== "" && valueTo !== "" && dateField !== "" && phoneNo !== "") {
      // setValue("");
      // setValueTo("");
      // setPhoneNo("");
      // setFlightAdult(1);
      // setFlightChild(0);
      // setFlightInfant(0);
      // setSuccessOpen(true);

      emailjs
        .sendForm(
          "service_524jcfc",
          "template_c17sh2l",
          form.current,
          "L8E3OR3Pt0z9SXHOS"
        )
        .then(
          (res) => {
            // setTimeout(() => {
            //   setSuccessOpen(false);
            // }, 8000);
          },
          (error) => {
            console.log("error");
          }
        );
    }
  };

  const [verifyModal, setverifyModal] = useState(false);
  const [verifyNumber, setverifyNumber] = useState("");

  const Verify = (e) => {
    e.preventDefault();
    sendEmail();
    if (value !== "" && valueTo !== "" && dateField !== "" && phoneNo !== "") {
      setverifyModal(true);
    }
  }


  const Confirm_Send = async () => {
    let url = `https://apilayer.net/api/validate?access_key=cc68c1ad51008fa3e000bd1ab4b8d393&number=${verifyNumber}&country_code=GB&format=1`;
    const response = await fetch(url);
    const text = await response.json();
    if(text.valid == true && value !== "" && valueTo !== "" && dateField !== "" && phoneNo !== ""){
      setverifyModal(false);
      setSuccessOpen(true);
      sendEmail();
      setValue("");
      setValueTo("");
      setPhoneNo("");
      setFlightAdult(1);
      setFlightChild(0);
      setFlightInfant(0);
      setTimeout(() => {
        setSuccessOpen(false);
      }, 8000);
    }else{
      alert('Please provide a valid UK number only.')
    }
  }


  const [checkData, setCheckData] = useState([]);

  const DropDownView = ({ type, valueType }) => {
    if (valueTo !== "" || (value !== "" && checkData.length === 0)) {
      import("./dummyData").then(({ default: data1 }) => {
        console.log(data1, "checkDataIn");
        setCheckData(data1);
      });
    }
    return (
      <div ref={refOne} className="auto_complete_form">
        {checkData
          ?.filter((item) => {
            const searchTerm = valueType.toLowerCase();
            const fullName = item.full_name.toLowerCase();
            if (fullName === searchTerm) {
              setAutoCompleteFrom(false);
              setAutoCompleteTo(false);
            }
            if (searchTerm === "") {
              return fullName;
            } else {
              return (
                searchTerm &&
                fullName.includes(searchTerm) &&
                fullName !== searchTerm
              );
            }
          })
          .map((item) => (
            <div
              onClick={() => onSearch(item.full_name, type)}
              className="dropdown-row"
              key={item.full_name}
            >
              <spam style={{ fontWeight: "800" }}>
                {item.full_name.split(",")[0]},
              </spam>
              {item.full_name.split(",")[1]}
            </div>
          ))}
      </div>
    );
  };
  const onChange = (e, type) => {
    if (type === "one") {
      setAutoCompleteTo(true);
    } else setAutoCompleteFrom(true);
    if (type === "one") {
      setValueTo(e.target.value);
    } else setValue(e.target.value);
  };

  const onSearch = (searchTerm, type) => {
    if (type === "one") {
      setValue(searchTerm);
    } else setValueTo(searchTerm);
  };
  return (
    <div className={styling ? "form_bookNow_wrapper" : "wrapper_home"}>
      <SuccessModal
        successOpen={successOpen}
        handleClose={handleClose}
        successText="trip"
      />

      <div
        className={
          styling ? "cheap_trip_form_header_text" : "trip_form_header_text"
        }
      >
        <RadioGroup
          style={{ display: "flex" }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Round Trip"
          name="radio-buttons-group"
        >
          <div style={{ display: "flex" }}>
            <FormControlLabel
              color="success"
              value="Round Trip"
              style={{ color: "black" }}
              onClick={() => setSelectTripWay("Round Trip")}
              control={<Radio />}
              label="Round Trip"
            />
            <FormControlLabel
              value="One Way"
              style={{ color: "black" }}
              onClick={() => setSelectTripWay("One Way")}
              label="One Way"
              control={<Radio />}
            />
          </div>
        </RadioGroup>
      </div>

      <form style={{ display: "flex" }} ref={form} onSubmit={Verify}>
        <Grid
          container
          md={12}
          sm={12}
          xs={12}
          style={{
            display: styling ? "" : "flex",
            backgroundColor: styling ? "" : "white",
            alignItems: "center",
            width: "100%",
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Grid
            item
            style={{
              marginRight: 2.5,
              marginLeft: 2.5,
              height: 80,
            }}
            md={styling ? 12 : 2.1}
            sm={3.7}
            xs={11.9}
          >
            <div style={{ display: "flex" }}>
              <MdOutlineLocalAirport
                style={{
                  transform: "rotate(45deg)",
                  fontSize: 22,
                  marginRight: 3,
                  marginTop: -2,
                }}
              />
              <legend className={styling ? "cheap_main_text" : "main_text"}>
                FLYING FROM
              </legend>
            </div>
            <span>
              <Input
                className={styling ? "cheap_input_container" : "inputContainer"}
                name="name"
                type="text"
                style={{
                  color: "gray",
                  fontSize: styling ? 14 : 17,
                }}
                disableUnderline
                value={value.split(",")[0]}
                id={styling ? "bookNow" : "text"}
                autoComplete="off"
                onChange={(e) => onChange(e)}
                placeholder="City or Airport"
                startAdornment={
                  <InputAdornment position="start">
                    <RoomIcon
                      style={{ width: 20, height: 20, color: "black" }}
                    />
                  </InputAdornment>
                }
              />
            </span>
            {validationTo && (
              <div className={styling ? "styling_error" : "error"}>
                This field is required.
              </div>
            )}

            {autoCompleteFrom ? (
              <DropDownView type={"one"} valueType={value} />
            ) : null}
          </Grid>

          <Grid
            item
            style={{
              marginRight: 2.5,
              marginLeft: 2.5,
              height: 80,
            }}
            md={styling ? 12 : 2.1}
            sm={3.7}
            xs={11.9}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <MdOutlineLocalAirport
                style={{
                  transform: "rotate(135deg)",
                  fontSize: 22,
                  marginRight: 3,
                }}
              />
              <legend className={styling ? "cheap_main_text" : "main_text"}>
                FLYING TO
              </legend>
            </div>
            <Input
              className={styling ? "cheap_input_container" : "inputContainer"}
              style={{
                color: "gray",
                fontSize: styling ? 14 : 17,
              }}
              name="text1"
              type="text"
              value={valueTo.split(",")[0]}
              disableUnderline
              id={styling ? "bookNow" : "text"}
              autoComplete="off"
              onChange={(e) => onChange(e, "one")}
              placeholder="City or Airport"
              startAdornment={
                <InputAdornment position="start">
                  <RoomIcon style={{ width: 20, height: 20, color: "black" }} />
                </InputAdornment>
              }
            />
            {validationFrom && (
              <div className={styling ? "styling_error" : "error"}>
                This field is required.
              </div>
            )}
            {autoCompleteTo ? <DropDownView valueType={valueTo} /> : null}
          </Grid>
          <Grid
            style={{
              marginRight: 2.5,
              height: 80,
            }}
            item
            md={styling ? 12 : 2.5}
            sm={3.7}
            xs={11.9}
          >
            <div>
              <DateRangeComp
                styling={styling}
                validationDate={validationDate}
                selectTripWay={selectTripWay}
                setDateField={setDateField}
                dateField={dateField}
                headingText="DEPARTURE-RETURN DATE"
              />
            </div>
          </Grid>
          <Grid
            style={{
              marginRight: 2.5,
              height: 80,
            }}
            item
            md={styling ? 12 : 2.4}
            sm={3.7}
            xs={11.9}
          >
            <legend className={styling ? "cheap_main_text" : "main_text"}>
              PASSENGERS
            </legend>
            <div onClick={handleOpen}>
              <Input
                className={styling ? "cheap_input_container" : "inputContainer"}
                id={styling ? "bookNow" : "text"}
                name="counter"
                autoComplete="off"
                disableUnderline
                value={`(${flightAdult}) Adult, (${flightChild}) Child, (${flightInfant}) Infant`}
                placeholder={`(${flightAdult}) Adult, (${flightChild}) Child, (${flightInfant}) Infant`}
                style={{
                  color: "gray",
                  fontSize: styling ? 14 : 17,
                }}
              />
            </div>

            {open ? (
              <>
                <div
                  ref={refOne}
                  className={"book_now_mainPassenger"}
                  style={{
                    position: "absolute",
                  }}
                >
                  <div className="textPassenger">Passengers</div>
                  <div className="divider"></div>
                  <div className="categoryContainer">
                    <div className="passengerCategory">Adult</div>
                    <div className="Adultbutton">
                      <div
                        className="counterStyle"
                        onClick={() => flightPassengerDec("adult")}
                      >
                        -
                      </div>
                      <h1 className="countText">{flightAdult}</h1>
                      <div
                        onClick={() => flightPassengerInc("adult")}
                        className="AdultIncrease"
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="categoryContainer">
                    <div className="passengerCategory">Child</div>
                    <div className="Childbutton">
                      <div
                        onClick={() => flightPassengerDec("child")}
                        className="counterStyle"
                      >
                        -
                      </div>
                      <h1 className="count1Text">{flightChild}</h1>
                      <div
                        onClick={() => flightPassengerInc("child")}
                        className="AdultIncrease"
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="categoryContainer">
                    <div className="passengerCategory">Infant</div>
                    <div className="Infantbutton">
                      <div
                        onClick={() => flightPassengerDec("")}
                        className="counterStyle"
                      >
                        -
                      </div>
                      <h1 className="count2Text">{flightInfant}</h1>
                      <div
                        onClick={() => flightPassengerInc("")}
                        className="AdultIncrease"
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="divider" />
                  <div onClick={() => setOpen(!open)} className="doneContainer">
                    <div className="doneButtonStyle">Done</div>
                  </div>
                </div>
              </>
            ) : null}
          </Grid>
          <Grid
            style={{ height: 80 }}
            item
            md={styling ? 12 : 1.8}
            sm={3.7}
            xs={11.9}
          >
            <legend className={styling ? "cheap_main_text" : "main_text"}>
              PHONE <small>(UK NUMBER ONLY)</small>
            </legend>
            <Input
              className={styling ? "cheap_input_container" : "inputContainer"}
              style={{
                color: "gray",
                fontSize: styling ? 14 : 17,
              }}
              type="number"
              disableUnderline
              name="phone"
              autoComplete="off"
              fullWidth
              value={phoneNo}
              onChange={(e) => handlePhone(e)}
              id={styling ? "bookNow" : "text"}
              placeholder="+44-XXXX-XXXX"
              startAdornment={
                <InputAdornment position="start">
                  <PhoneIcon
                    style={{ width: 20, height: 20, color: "black" }}
                  />
                </InputAdornment>
              }
            />
            {validationPhone && (
              <div className={styling ? "styling_error" : "error"}>
                This field is required.
              </div>
            )}
          </Grid>

          <Grid
            style={{
              marginTop: 20,
              marginBottom: 10,
              height: 45,
            }}
            item
            md={styling ? 12 : 0.8}
            sm={12}
            xs={11.9}
          >
            <button
              type="submit"
              className="find_now_button"
            >
              Submit
            </button>
          </Grid>
        </Grid>
      </form>

      <div className={verifyModal ? 'show number_confirm' : 'number_confirm'}>
        <div className="text-center col-md-8 bg-white py-5">
          <h3>Oops! Seems like an invalid number</h3>
          <p>
            Please Provide Us Your Correct Number & Get Guaranteed Cheapest
            Fares When You Talk To Our Travel Expert.
          </p>

          <Input
            className={styling ? "cheap_input_container" : "inputContainer"}
            name=""
            type="number"
            style={{
              color: "gray",
              fontSize: styling ? 14 : 17,
            }}
            disableUnderline
            autoComplete="off"
            placeholder="Enter Valid Number"
            value={verifyNumber} 
            onChange={e => setverifyNumber(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <PhoneIcon style={{ width: 20, height: 20, color: "black" }} />
              </InputAdornment>
            }
          />

          <button type="button" onClick={Confirm_Send} className="find_now_button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlightForm;

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

// Import Swiper modules
import { Navigation, Autoplay } from "swiper/modules";

const content = [
  {
    title: "Cheap Flights to",
    countryText: "Africa",
    image: require("../../asstes/images/all images/African.webp"),
    mobile: require("../../asstes/mobileImages/African-mobile.webp"),
    description: "Discover amazing places at exclusive deals",
  },
  {
    title: "Cheap Flights to",
    countryText: "Canada",
    description: "Discover amazing places at exclusive deals",
    image: require("../../asstes/images/all images/canada.webp"),
    mobile: require("../../asstes/mobileImages/canada-mobile.webp"),
  },
  {
    title: "Cheap Flights to",
    countryText: "Australia",
    description: "Discover amazing places at exclusive deals",
    image: require("../../asstes/images/all images/australia.webp"),
    mobile: require("../../asstes/mobileImages/australia-mobile.webp"),
  },
];

const responsive = window.innerWidth > 500;

export default function HomeTwoSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <>
      <Swiper
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        speed={1000}
        loop={true}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay={{
          delay: 2000,
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
        modules={[Navigation, Autoplay]}
      >
        {content.map((item, index) => (
          <SwiperSlide
            key={index}
            className="swiper_slider_two"
            style={{
              background: responsive
                ? `url(${item.image})`
                : `url(${item.mobile})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              className="inner"
            >
              <h1
                style={{ color: "white", fontFamily: "Bitter" }}
                className="react-slide-title"
              >
                {item.title}{" "}
                <span style={{ color: "#0095fa" }}>{item.countryText}</span>{" "}
              </h1>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev" ref={navigationPrevRef}></div>
        <div className="swiper-button-next" ref={navigationNextRef}></div>
      </Swiper>
    </>
  );
}
  
import React, { Component } from 'react'
import Slider from "react-slick";
import {AiOutlineRight,AiOutlineLeft} from "react-icons/ai"


const destination = [
    {
        title: 'Hafsa Iqbal',
        description:"I have planned many trips before with various travel agents and I must say you are the best agent i have had the pleasure to interact with .I thankyou very much for all your work and dedication to make this trip happen"
        ,
        location:"United Kingdom" 
    },
    {
        title: 'Malik Abdullah',
        description:"I booked my honeymoon trip with Flightsflair to Santorni last month. They offered me with the best honeymoon package. I always wanted to have a luxury trip and it was well thought out and planned by their expert team. I am really grateful for their services. Would definitely recommend them to my friends and family members."
        ,
        location:"United Kingdom" 
    },
    {
        title: 'Imran Saeed ',
        description:"Extremely professional and helpful staff very efficient customer service and understanding. Will definitely be booking my tickets through them from now on 5star service they provide. Huge thanks to Ebad who dealt with the booking very helpful and smart guy really impressed with his customer service."
        ,
        location:"United Kingdom" 


    },
    {
        title: 'Rimsha Tariq',
        description:"First time experience with FlightsFlair. Their staff is very competent and efficient. They provide very good rates as compare to market prices.Hope to avail your services again."

        ,
        location:"United Kingdom" 
    },
    {
        title: 'Asim ',
        description:"Our experience with Flightsflair has gone from good to great. From the moment we sat down, Ebad found us comparable flights to what we had found ourselves but with all the guarantees. We booked on the spot. It’s been a fantastic experience."
        ,
        location:"United Kingdom" 

    },
    {
        title: 'watson ',
        description:"Flightflair travel agency provided fantastic service and communication. Have five stars. My emails were swiftly acknowledged. The vacation went off without a hitch, with everything booked and as advertised. Exceptional communication from beginning to end."      
        ,
        location:"United States" 

    },
    {
        title: 'Mrs.Kia ',
        description:"My booking was handled by a young man called ebad. He was professional and polite, and also got me a good deal. Great company."      
        ,
        
        location:"United Kingdom " 

    },
   



]


var bg1 = require('./../../images/background/bg1.jpg');

function SampleNextArrow(props) {
    const { onClick } = props;
    return <div  className="la la-angle-right nav-right" onClick={onClick} ><AiOutlineRight style={{marginBottom:-3}}/></div>;
  }
  
  function SamplePrevArrow(props) {
    const { onClick } = props;
    return <div className="la la-angle-left nav-left" onClick={onClick} ><AiOutlineLeft style={{marginBottom:-3}}/></div>;
  }


class Slick2 extends Component {

    render() {
        var settings = {
            dots: false,
            slidesToShow: 3,
            infinite: true,
            autoplay: false,
            speed: 2000,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,

            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
        return (
            <div>
                <div className="section-full bg-white content-inner-1 testimonial-one-area overlay-black-dark" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                    <div className="container">
                        <div className="section-head style1 text-center text-white">
                            <h2 className="box-title">FlightsFlair Reviews</h2>
                            <div className="dlab-separator bg-primary"></div>
                            <p>Reviews are like windows into the soul of a business, offering insights into the experiences and satisfaction of its customers</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Slider className="testimonial-center nav-btn-center-lr white" {...settings}>
                                    {destination.map((item, index) => (
                                        <div  className="item" key={index}>
                                            <div style={{minHeight:400,marginTop:-60}} className="testimonial-1 style-2" >
                                                <div className="testimonial-detail clearfix">
                                                  
                                                    <strong style={{fontSize:15}} className="testimonial-name text-primary">{item.title}</strong>
                                                    <span className="testimonial-position">{item.location}</span>
                                                </div>
                                                <div className="testimonial-text">
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default Slick2;
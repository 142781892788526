import React, { useState } from "react";
import "./form.css";
import "./index.css";

import FlightForm from "./FlightForm";
import HotelsForm from "./HotelsForm";
const TripForm = ({ fromImg, styling }) => {
  const [selectTripType, setSelectTripType] = useState("Flights");

  const handleTripSelect = (type) => {
    setSelectTripType(type);
  };

  return (
    <div style={{ marginTop: styling ? 0 : -54 }} className="mainContainer">
      <div
        style={{ backgroundColor: styling ? "rgba(0, 149, 250,0.6)" : "" }}
        className={styling ? "cheap_field_container" : "fields_Container"}
      >
        <div>
          {styling ? (
            <div className="styl_tab_container">
              <div
                className={
                  selectTripType === "Flights"
                    ? "select_tab_form_styl"
                    : "tab_form_styl"
                }
                onClick={() => handleTripSelect("Flights")}
              >
                Flights
              </div>
              <div
                className={
                  selectTripType === "Hotels"
                    ? "select_tab_form_styl"
                    : "tab_form_styl"
                }
                onClick={() => handleTripSelect("Hotels")}
              >
                Hotels
              </div>
            </div>
          ) : (
            <div className="tab_container">
              <div
                className={
                  selectTripType === "Flights" ? "select_tab_form" : "tab_form"
                }
                onClick={() => handleTripSelect("Flights")}
              >
                Flights
              </div>
              <div
                className={
                  selectTripType === "Hotels" ? "select_tab_form" : "tab_form"
                }
                onClick={() => handleTripSelect("Hotels")}
              >
                Hotels
              </div>
            </div>
          )}
        </div>
        <div>
          {selectTripType === "Flights" ? (
            <FlightForm styling={styling} />
          ) : (
            <HotelsForm styling={styling} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TripForm;

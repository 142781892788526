import { Input, InputAdornment } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "./form.css";
import RoomIcon from "@mui/icons-material/Room";
import emailjs from "@emailjs/browser";
import DateRangeComp from "./DateRangeComp";
import Grid from "@mui/material/Grid";
import PhoneIcon from "@mui/icons-material/Phone";
import SuccessModal from "../markup/components/SuccessModal";

const HotelsForm = ({ styling }) => {
  const [autoCompleteTo, setAutoCompleteTo] = useState(false);
  const [valueTo, setValueTo] = useState("");
  const [dateField, setDateField] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [validationFrom, setValidationFrom] = useState(false);
  const [validationDate, setValidationDate] = useState(false);
  const [validationPhone, setValidationPhone] = useState(false);
  const [open, setOpen] = useState(false);

  const [successOpen, setSuccessOpen] = React.useState(false);
  const [room, setRoom] = useState(1);
  const [hotelAdult, setHotelAdult] = useState(2);
  const [hotelChildren, setHotelChildren] = useState(0);
  const [tableValue, setTableValue] = useState(2);
  const [tableFour, setTableFour] = useState(4);

  const hotelGuestIncrement = (value) => {
    if (value === "room") {
      setRoom(room + 1);
    } else if (value === "adult") {
      setHotelAdult(hotelAdult + 1);
      if (hotelAdult + 1 === tableFour) {
        setRoom(room + 1);
        setHotelAdult(hotelAdult + 1);
        setTableFour(4 * tableValue);
        setTableValue(tableValue + 1);
      }
    } else {
      setHotelChildren(hotelChildren + 1);
    }
  };
  const hotelGuestDecrement = (value) => {
    if (value === "room") {
      if (room > 1) return setRoom(room - 1);
    } else if (value === "adult") {
      if (hotelAdult > 1) return setHotelAdult(hotelAdult - 1);
    } else {
      if (hotelChildren > 0) return setHotelChildren(hotelChildren - 1);
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
  };

  const form = useRef();
  const refOne = useRef(null);
  const handlePhone = (e) => {
    setPhoneNo(e.target.value);
  };



  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setAutoCompleteTo(false);
      setOpen(false);
    }
  };

 

  const handleOpen = () => {
    setOpen(!open);
  };
  const sendEmail = (e) => {
   // e.preventDefault();
    setValidationFrom(valueTo === "");
    setValidationDate(dateField === "");
    setValidationPhone(phoneNo === "");
  
    if ( valueTo !== "" && dateField !== "" && phoneNo !== "") {
      // setValueTo("");
      // setPhoneNo("");
      // setRoom(1);
      // setHotelAdult(2);
      // setHotelChildren(0);
      // setSuccessOpen(true);
  
      emailjs.sendForm(
        "service_524jcfc",
          "template_mi47gat",
          form.current,
          "L8E3OR3Pt0z9SXHOS"
      ).then(
        (res) => {
          // setTimeout(() => {
          //   setSuccessOpen(false);
          // }, 8000);
        },
        (error) => {
          console.log("error");
        }
      );
    }
  };

  const [verifyModal, setverifyModal] = useState(false);
  const [verifyNumber, setverifyNumber] = useState("");

  const Verify = (e) => {
    e.preventDefault();
    sendEmail();
    if (valueTo !== "" && dateField !== "" && phoneNo !== "") {
      setverifyModal(true);
    }
  }


  const Confirm_Send = async () => {
    let url = `https://apilayer.net/api/validate?access_key=cc68c1ad51008fa3e000bd1ab4b8d393&number=${verifyNumber}&country_code=GB&format=1`;
    const response = await fetch(url);
    const text = await response.json();
    if(text.valid == true && valueTo !== "" && dateField !== "" && phoneNo !== ""){
      setverifyModal(false);
      setSuccessOpen(true);
      sendEmail();
      setValueTo("");
      setPhoneNo("");
      setTimeout(() => {
        setSuccessOpen(false);
      }, 8000);
    }else{
      alert('Please provide a valid UK number only.')
    }
  }

  const [checkData, setCheckData] = useState([]);

  const DropDownView = ({ type, valueType }) => {
    if (valueTo !== "" && checkData.length === 0) {
      import("./dummyData").then(({ default: data1 }) => {
        console.log(data1, "checkDataIn");
        setCheckData(data1);
      });
    }
    return (
      <div ref={refOne} className="auto_complete_form">
      {checkData
        .filter((item) => {
          const searchTerm = valueTo.toLowerCase();
          const fullName = item.full_name.toLowerCase();
          if (fullName === searchTerm) {
            setAutoCompleteTo(false);
          }
          if (searchTerm === "") {
            return fullName;
          } else {
            return (
              searchTerm &&
              fullName.includes(searchTerm) &&
              fullName !== searchTerm
            );
          }
        })
        .map((item) => (
          <div
            onClick={() => onSearch(item.full_name)}
            className="dropdown-row"
            key={item.full_name}
          >
            <spam style={{ fontWeight: "600",}}>
              {item.full_name.split(",")[0]},
            </spam>
            {item.full_name.split(",")[1]}
          </div>
        ))}
    </div>
    );
  };
  const onChange = (e, type) => {
  
      setAutoCompleteTo(true);
      setValueTo(e.target.value);
    }

  const onSearch = (searchTerm, type) => {
    setValueTo(searchTerm);
  };
  return (
    <div className={styling ? "form_bookNow_wrapper" : "wrapper_home"}>
      <SuccessModal
        successOpen={successOpen}
        handleClose={handleClose}
        successText="trip"
      />
      <form style={{ display: "flex" }} ref={form} onSubmit={Verify}>
        <Grid
          container
          md={12}
          sm={12}
          xs={12}
          style={{
            display: styling ? "" : "flex",
            backgroundColor: styling ? "" : "white",
            alignItems: "center",
            width: "100%",
            paddingTop: 15,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Grid
            item
            style={{
              marginRight: 5,
              marginLeft: 2.6,
              height: 80,
            }}
            md={styling ? 12 : 2.6}
            sm={3.7}
            xs={11.9}
          >
            <legend className={styling ? "cheap_main_text" : "main_text"}>
              DESTINATION
            </legend>

            <Input
              className={styling ? "cheap_input_container" : "inputContainer"}
              style={{ color: "gray", fontSize: 17 }}
              name="text1"
              type="text"
              value={valueTo.split(",")[0]}
              disableUnderline
              id={styling ? "bookNow" : "text"}
              autoComplete="off"
              onChange={(e) => onChange(e)}
              placeholder="Where are you going?"
              startAdornment={
                <InputAdornment position="start">
                  <RoomIcon style={{ width: 20, height: 20, color: "gray" }} />
                </InputAdornment>
              }
            />
            {validationFrom && (
              <div className={styling ? "styling_error" : "error"} >
                This field is required.
              </div>
            )}
            {autoCompleteTo ? (
              <DropDownView/>
            ) : null}
          </Grid>
          <Grid
            style={{
              marginRight: 5,
              height: 80,
            }}
            item
            md={styling ? 12 : 2.6}
            sm={3.7}
            xs={11.9}
          >
            <div>
              <DateRangeComp
                styling={styling}
                selectTripWay="Round Trip"
                validationDate={validationDate}
                setDateField={setDateField}
                dateField={dateField}
                headingText="CHECK IN-CHECK OUT"
              />
            </div>
          </Grid>
          <Grid
            style={{
              marginRight: 5,
              height: 80,
            }}
            item
            md={styling ? 12 : 2.6}
            sm={3.7}
            xs={11.9}
          >
            <legend className={styling ? "cheap_main_text" : "main_text"}  >
              GUESTS
            </legend>
            <div onClick={handleOpen}>
              <Input
                className={styling ? "cheap_input_container" : "inputContainer"}
                id={styling ? "bookNow" : "text"}
                name="counter"
                autoComplete="off"
                style={{
                  color: "gray",
                  fontSize: 17,
                }}
                disableUnderline
                value={`(${room}) Rooms, (${hotelAdult}) Adults, (${hotelChildren}) Children`}
                placeholder={`(${room}) Rooms, (${hotelAdult}) Adults, (${hotelChildren}) Children`}
                color="black"
              />
            </div>

            {open ? (
              <>
                <div
                  ref={refOne}
                  className={"book_now_mainPassenger"}
                  style={{
                    position: "absolute",
                  }}
                >
                  <div className="textPassenger">Guests</div>
                  <div className="divider"></div>
                  <div className="categoryContainer">
                    <div className="passengerCategory">Rooms</div>
                    <div className="Adultbutton">
                      <div
                        className="counterStyle"
                        onClick={() => hotelGuestDecrement("room")}
                      >
                        -
                      </div>
                      <h1 className="countText">{room}</h1>
                      <div
                        onClick={() => hotelGuestIncrement("room")}
                        className="AdultIncrease"
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="categoryContainer">
                    <div className="passengerCategory">Adults</div>
                    <div className="Childbutton">
                      <div
                        onClick={() => hotelGuestDecrement("adult")}
                        className="counterStyle"
                      >
                        -
                      </div>
                      <h1 className="count1Text">{hotelAdult}</h1>
                      <div
                        onClick={() => hotelGuestIncrement("adult")}
                        className="AdultIncrease"
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="categoryContainer">
                    <div className="passengerCategory">Children</div>
                    <div className="Infantbutton">
                      <div
                        onClick={() => hotelGuestDecrement()}
                        className="counterStyle"
                      >
                        -
                      </div>
                      <h1 className="count2Text">{hotelChildren}</h1>
                      <div
                        onClick={() => hotelGuestIncrement("")}
                        className="AdultIncrease"
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="divider" />
                  <div onClick={() => setOpen(!open)} className="doneContainer">
                    <div className="doneButtonStyle">Done</div>
                  </div>
                </div>
              </>
            ) : null}
          </Grid>
          <Grid
            style={{
              height: 80,
            }}
            item
            md={styling ? 12 : 2.6}
            sm={3.7}
            xs={11.9}
          >
            <legend className={styling ? "cheap_main_text" : "main_text"} >
              PHONE <small>(UK NUMBER ONLY)</small>
            </legend>
            <Input
              className={styling ? "cheap_input_container" : "inputContainer"}
              style={{ color: "gray", fontSize: 17}}
              type="number"
              disableUnderline
              name="phone"
              autoComplete="off"
              fullWidth
              value={phoneNo}
              onChange={(e) => handlePhone(e)}
              id={styling ? "bookNow" : "text"}
              placeholder="+44-XXXX-XXXX"
              startAdornment={
                <InputAdornment position="start">
                  <PhoneIcon
                    style={{ width: 20, height: 20, color: "gray" }}
                  />
                </InputAdornment>
              }
            />
            {validationPhone && (
              <div className={styling ? "styling_error" : "error"} >
                This field is required.
              </div>
            )}
          </Grid>
          <Grid
            style={{
              marginBottom: 0,
            }}
            item
            md={styling ? 12 : 0.8}
            sm={12}
            xs={11.9}
          >
            <button type="submit" className="find_now_button" >
              Submit
            </button>
          </Grid>
        </Grid>
      </form>

      <div className={verifyModal ? 'show hotel_number_confirm' : 'hotel_number_confirm'}>
        <div className="text-center col-md-8 bg-white py-5">
          <h3>Oops! Seems like an invalid number</h3>
          <p>
            Please Provide Us Your Correct Number & Get Guaranteed Cheapest
            Fares When You Talk To Our Travel Expert.
          </p>

          <Input
            className={styling ? "cheap_input_container" : "inputContainer"}
            name=""
            type="number"
            style={{
              color: "gray",
              fontSize: styling ? 14 : 17,
            }}
            disableUnderline
            autoComplete="off"
            placeholder="Enter Valid Number"
            value={verifyNumber} 
            onChange={e => setverifyNumber(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <PhoneIcon style={{ width: 20, height: 20, color: "black" }} />
              </InputAdornment>
            }
          />

          <button type="button" onClick={Confirm_Send} className="find_now_button">
            Submit
          </button>
        </div>
      </div>

    </div>
  );
}

export default HotelsForm;

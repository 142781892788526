import React, { useEffect,useState } from "react";
import TripForm from "../../TripForm";
import HomeTwoSlider from "./../components/HomeTwoSlider";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slick2 from "./Slick2";
import HomeBlog from "../Element/HomeBlog";
import BookNow from "../../BookNow";
import axios from "axios";

function Homepage2() {

  async function GetIP() {
    // let res = await axios.get("https://api.ipify.org/?format=json");
    let res = await axios.get("http://ipwho.is/");
    if(res.data.ip && res.data.city && res.data.country){
      axios.post('https://www.flightsflair.com/Admin/userip.php', {
        ip: res.data.ip,
        city: res.data.city,
        country: res.data.country
    })
    .then(function (response) {
      
    })
    .catch(function (error) {
      console.log(error);
    });
    }
  }

  useEffect( () => {
    GetIP();
  }, []);

  

  return (
    <div>
      <HomeTwoSlider />

      <div className="content-block" id="page_content">
        <div>
          <TripForm />
        </div>

        <div className="section-full bg-white content-inner-2">
          <div className="container">
            <div className="section-head style1 text-black text-center">
              <h2 style={{}}>Popular Destinations</h2>
              <div className="dlab-separator bg-primary"></div>
              <p style={{}}>
                There are places that leave footprints on your soul, where every
                step tells a story of wonder and discovery
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="featured-bx style2">
                  <div className="featured-media">
                    <img
                      loading="lazy"
                      src={require("../../asstes/images/all images/africa-card.webp")}
                      alt=""
                    />
                    <div className="featured-tag" style={{}}>
                      5 Days
                    </div>
                  </div>
                  <div className="featured-content text-white">
                    <div>
                      <h1 style={{ fontSize: 30 }} className="title">
                        <div>Africa</div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="featured-bx style2">
                  <div className="featured-media">
                    <img
                      loading="lazy"
                      src={require("../../asstes/images/all images/canada-card.webp")}
                      alt=""
                    />
                    <div className="featured-tag" style={{}}>
                      7 Days
                    </div>
                  </div>
                  <div className="featured-content text-white">
                    <div>
                      <h1 style={{ fontSize: 30 }} className="title">
                        <div>Canada</div>
                      </h1>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="featured-bx style2">
                  <div className="featured-media">
                    <img
                      loading="lazy"
                      src={require("../../asstes/images/all images/Friendly-card.webp")}
                      alt=""
                    />
                    <div className="featured-tag" style={{}}>
                      3 Days
                    </div>
                  </div>
                  <div className="featured-content text-white">
                    <div>
                      <h1 style={{ fontSize: 30 }} className="title">
                        <div>Friendly Santorini</div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="featured-bx style2">
                  <div className="featured-media">
                    <img
                      loading="lazy"
                      src={require("../../asstes/images/all images/australia-card.webp")}
                      alt=""
                    />
                    <div className="featured-tag" style={{}}>
                      2 Days
                    </div>
                  </div>
                  <div className="featured-content text-white">
                    <div>
                      <h1 style={{ fontSize: 30 }} className="title">
                        <div>Australia</div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="featured-bx style2">
                  <div className="featured-media">
                    <img
                      loading="lazy"
                      src={require("../../asstes/images/all images/SouthAmerica-card.webp")}
                      alt=""
                    />

                    <div className="featured-tag" style={{}}>
                      6 Days
                    </div>
                  </div>
                  <div className="featured-content text-white">
                    <div>
                      <h1 style={{ fontSize: 30 }} className="title">
                        <div>South America </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BookNow />

        <div className="section-full content-inner bg-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  src={require("./../../images/gallery/img3.webp")}
                  width={"100%"}
                />
              </div>
              <div className="col-md-6">
                <h3>HOW DO I KNOW I CAN TRUST THIS TRAVEL AGENCY?</h3>
                <p>
                  Professional Travel a Direct Travel Company is extremely
                  cautious when it comes to choosing our business partners and
                  vendors. This vetting of travel suppliers and support of the
                  ‘good ones’ over the past 25+ years has given us preferred
                  access to the world’s top trusted suppliers.
                </p>

                <br />

                <h3>
                  WILL I PAY MORE FOR VACATION IF I BOOK THROUGH YOU THAN AN
                  ONLINE BOOKING SITE?
                </h3>
                <p>
                  No. We have access to all the same pricing for resorts as the
                  online booking sites do and we can price-match online booking
                  engine pricing when comparing apples to apples. <br />
                  <br /> In many cases, we can find cheap Airline Flight Tickets
                  and other promotional offerings that are not offered to the
                  general public.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section-full content-inner bg-gray">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h3>FLY WITH US!</h3>
                <p>
                  Now flight booking is easy with FlightsFlair as FlightsFlair
                  offering you the best cheap flight tickets for UK to Pakistan,
                  when you about to make a booking, all you have to do is follow
                  the step which is mention in the page, There are several deal
                  that FlightsFlair offers on a daily and monthly basis , These
                  deals change constantly, you can easily select the deal option
                  in our website, you can comfortably find about the deals and
                  how they are valid, FlightsFlair empower you great discount on
                  cheap flight and as well introduced the new reasonable flight
                  expression with best price guarantee, as customer care service
                  is all in one and first rate and we care a lot of our flight
                  reputation, our dedicated team is always here for you to
                  provide you best service and closely collaborate with our
                  professionals and consultants to create the best price for
                  flight tickets to our customers , some of the procedures I
                  mention to simply book ticket at cheaper fare, such as go to
                  the search box , fill the foam and to the destination , fill
                  the date you want to ticket for, fill the number of passenger,
                  click on the search button and get a list of flight you want
                  to book which is available at low fare, the fare shown above
                  are subject to change until the tickets are booked, you can
                  also avail some cashback offers to get the best deal, and you
                  also book a flight ticket for different dates, after filling
                  the foam and to the section in the search box you have to fill
                  in the dates in the depart section to search for a flight, you
                  as well get an amazing offer by book round trip, to book a
                  cheap flight you need to plan your trip and book your ticket
                  well in advance
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={require("./../../images/gallery/img1.webp")}
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section-full content-inner bg-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  src={require("./../../images/gallery/img2.webp")}
                  width={"100%"}
                />
              </div>
              <div className="col-md-6">
                <h3>OUR VISION</h3>
                <p>
                  Our vision is to provide you Cheap Flights To Africa From
                  United Kingdom, as Africa is the largest continent so we will
                  provide you Cheap Flights, Airline deals from United Kingdom
                  To Africa. <br />
                  <br /> Travelers don’t pay a fee to work with our agents and
                  won’t pay higher rates when working with us. In fact,
                  oftentimes it’s the opposite. Because of our relationships, we
                  have access to rates and upgrades that the average client does
                  not. We always provide you Cheap Tickets from United Kingdom.
                </p>

                <br />

                <h3>24/7 CUSTOMER SATISFACTION</h3>
                <p>
                  We are available day or night, before, during and after your
                  trip. In the event of an emergency, a delay or an unexpected
                  disruption, our agents are there to reroute, rebook and get
                  you where you need to be. <br />
                  <br /> As we are providing you CHEAP FLIGHTS TO PAKISTAN FROM
                  UNITED KINGDOM , so get ready to avail this offer just fill
                  the form and put date you want to ticket for, fill the number
                  of passenger, click on the search button and get a list of
                  flight you want to book which is available at CHEAP PRICE.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section-full content-inner bg-gray">
          <div className="container">
            <div className="section-head style1 text-black text-left text-center">
              <h2>Best Holiday Packages</h2>
              <div className="dlab-separator bg-primary"></div>
              <p>
                Discover the art of relaxation and exploration with our
                thoughtfully curated holiday packages, designed to transport you
                to your dream destinations
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="dlab-box packages-bx">
                  <div className="dlab-media">
                    <div>
                      <LazyLoadImage
                        alt="img5"
                        src={require("./../../images/gallery/img5.webp")}
                        placeholderSrc={require("./../../images/gallery/img5.webp")}
                      />
                    </div>
                    <span className="tag yellow">Best Seller</span>
                  </div>
                  <div className="dlab-info">
                    <div className="head-title">
                      <span className="time">2 Days 3 Night</span>
                      <h4 className="dlab-title">
                        <div>Weekdays in South America</div>
                      </h4>
                      <span className="location">
                        Peelamedu, Cbe, South America
                      </span>
                    </div>
                    <div className="package-content">
                      <div className="review-bx">
                        <ul className="rating-star">
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                        </ul>
                        <span>(5 Review)</span>
                      </div>
                      <div className="price-bx">
                        <del className="price-del">£700</del>
                        <span className="price-main">£550</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="dlab-box packages-bx">
                  <div className="dlab-media">
                    <div>
                      <LazyLoadImage
                        alt="img5"
                        src={require("./../../images/gallery/img2.webp")}
                        placeholderSrc={require("./../../images/gallery/img2.webp")}
                      />
                    </div>
                    <span className="tag yellow">Best Seller</span>
                  </div>
                  <div className="dlab-info">
                    <div className="head-title">
                      <span className="time">8 Days 7 Night</span>
                      <h4 className="dlab-title">
                        <div>Weekdays in India</div>
                      </h4>
                      <span className="location">Peelamedu, Cbe, India</span>
                    </div>
                    <div className="package-content">
                      <div className="review-bx">
                        <ul className="rating-star">
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                        </ul>
                        <span>(4 Review)</span>
                      </div>
                      <div className="price-bx">
                        <del className="price-del">£457</del>
                        <span className="price-main">£350</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="dlab-box packages-bx">
                  <div className="dlab-media">
                    <div>
                      <img
                        src={require("./../../images/gallery/img3.webp")}
                        alt=""
                      />
                    </div>
                    <span className="tag yellow">Best Seller</span>
                  </div>
                  <div className="dlab-info">
                    <div className="head-title">
                      <span className="time">8 Days 7 Night</span>
                      <h4 className="dlab-title">
                        <div>Weekdays in Australia</div>
                      </h4>
                      <span className="location">
                        Peelamedu, Cbe, Australia
                      </span>
                    </div>
                    <div className="package-content">
                      <div className="review-bx">
                        <ul className="rating-star">
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                        </ul>
                        <span>(2 Review)</span>
                      </div>
                      <div className="price-bx">
                        <del className="price-del">£250</del>
                        <span className="price-main">£200</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="dlab-box packages-bx">
                  <div className="dlab-media">
                    <div>
                      <LazyLoadImage
                        alt="img4"
                        src={require("./../../images/gallery/img4.webp")}
                        placeholderSrc={require("./../../images/gallery/img4.webp")}
                      />
                    </div>
                    <span className="tag yellow">Best Seller</span>
                  </div>
                  <div className="dlab-info">
                    <div className="head-title">
                      <span className="time">8 Days 7 Night</span>
                      <h4 className="dlab-title">
                        <div>Weekdays in Philippines</div>
                      </h4>
                      <span className="location">Weekdays in Philippines</span>
                    </div>
                    <div className="package-content">
                      <div className="review-bx">
                        <ul className="rating-star">
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                        </ul>
                        <span>(8 Review)</span>
                      </div>
                      <div className="price-bx">
                        <del className="price-del">£840</del>
                        <span className="price-main">£710</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Slick2 />

        <HomeBlog />

        <div className="seo_faqs">
          <div className="content">
            <div className="row text-center" id="about-text">
              <div
                className="text-center col-12"
                id="heading-text"
                style={{ visibility: "visible", animationName: "zoomInDown" }}
              >
                <div className="section-head style1 text-black text-center">
                  <h2 style={{ fontFamily: "bitter" }}>FAQ's</h2>
                  <div className="dlab-separator bg-primary"></div>
                </div>
              </div>
            </div>
            <br />

            <div>
              <input
                type="checkbox"
                id="faq_1"
                name="q"
                className="questions"
              />
              <div className="plus">+</div>
              <label for="faq_1" className="question">
                Looking for cheap flights to Pakistan !
              </label>
              <div className="answers">
                Welcome to our highly-rated travel agency, your one-stop
                solution for booking cheap airline flight tickets from the UK to
                Pakistan. We understand the importance of providing exceptional
                service while offering affordable prices. Travel to Pakistan Our
                experienced team of travel experts is committed to finding you
                the best deals Cheap Flights To Islamabad, Cheap Flights To
                Lahore also Cheap Airline Flight Tickets, and ensuring a
                seamless travel experience. With our extensive network of
                airlines and travel partners, Pakistan flights from UK we
                guarantee the most competitive fares on UK to Pakistan tickets.
                Whether you're planning a leisurely vacation, a business trip,
                or visiting friends and family, we are here to assist you. Our
                customer satisfaction is paramount, and we take pride in
                delivering top-quality service, excellent customer support, and
                prompt responses to your inquiries. Don't miss out on the
                opportunity to explore the mesmerizing wonders of Pakistan. Visa
                for Pakistan adventure tours Book your cheap airline flight
                tickets with our highly-rated travel agency today and embark on
                an unforgettable journey that fits your budget.
              </div>
            </div>

            <div>
              <input
                type="checkbox"
                id="faq_2"
                name="q"
                className="questions"
              />
              <div className="plus">+</div>
              <label for="faq_2" className="question">
                Are you searching for cheap airline flight tickets to Africa
                from the UK?
              </label>
              <div className="answers">
                Are you searching for cheap airline flight tickets to Africa
                from the UK? Look no further! We understand that affordability
                Explore Africa is key when planning your journey, Africa travel
                deals and we are here to help you find the best deals on
                flights. Travel to Africa With our extensive network of airlines
                and travel partners,Best deals to Pakistan we can assist you in
                discovering the most cost-effective options for your trip to
                Africa. Africa holidays Whether you dream of exploring the
                vibrant landscapes of South Africa, embarking on a safari
                adventure in Kenya, or experiencing the rich cultural heritage
                of Morocco, Safari holidays in Africa Travel agency for Africa,
                African beach vacations we will strive to find you the lowest
                Cheap Flights fares available. Africa travel tips Don't let
                budget constraints hinder your exploration of the magnificent
                continent of Africa. Best places to visit in Africa Book your
                cheap airline flight tickets today and set off on an
                extraordinary voyage without breaking the bank.
              </div>
            </div>

            <div>
              <input
                type="checkbox"
                id="faq_3"
                name="q"
                className="questions"
              />
              <div className="plus">+</div>
              <label for="faq_3" className="question">
                Trustworthy Travel Agency
              </label>
              <div className="answers">
                Looking for a reliable and trustworthy Travel agency in UK to
                cater to all your travel needs? Look no further than our
                top-rated agency! With years of experience and a proven track
                record,Affordable travel agency, Travel agency London we are the
                ultimate destination for all your travel requirements. Hotel
                booking UK, Our dedicated team of experts is committed to
                providing you with exceptional service, Cheap flights to
                Pakistan ensuring a hassle-free and unforgettable travel
                experience.Affordable Pakistan travel from booking flights,
                hotels, and transportation to crafting personalized itineraries,
                Luxury travel to Africa we handle every aspect of your trip with
                utmost professionalism. Visa assistance UK Our high-rated agency
                prides itself on customer satisfaction, offering competitive
                prices, reliable customer support, and a wide range of
                destination options. Corporate travel agency Whether you're
                dreaming of exotic beach getaways, thrilling adventures, or
                cultural explorations,Customer support UK Pakistan travel
                itinerary we have got you covered. Don't miss out on the
                opportunity to experience the world with the assistance of our
                trusted travel agency. Best deals to Pakistan Contact us today
                to unlock your next extraordinary journey!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Homepage2;

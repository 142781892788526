import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./bookNow.css";
import { useNavigate } from "react-router-dom";
const dataCheapFlights = [
  {
    title1: "Flights To Karachi",
    mainTitle: "Cheap Flights To Karachi, Pakistan From UK",
    flightUrl: "cheapflightstokarachi",
    date: "Thu 23 Mar - Thu 30 Mar",
    price: "£388*",
  },
  {
    title1: "Flights To Lahore",
    mainTitle: "Cheap Flights To Lahore, Pakistan From UK",
    flightUrl: "cheapflightstolahore",
    date: "Wed 10 May - Wed 31 May",
    price: "£399*",
  },
  {
    title1: "Flights To Islamabad",
    mainTitle: "Cheap Flights To Islamabad, Pakistan From UK",
    flightUrl: "cheapflightstoislamabad",
    date: "Tue 31 jan - Thu 16 Feb",
    price: " £399*",
  },
  {
    title1: "Flights To Peshawar",
    flightUrl: "cheapflightstopeshawar",
    mainTitle: "Cheap Flights To Peshawar, Pakistan From UK",
    date: "Thu 16 Mar - Mon 03 Apr",
    price: "£375*",
  },
  {
    title1: "Flights To Sialkot",
    flightUrl: "cheapflightstosialkot",
    mainTitle: "Cheap Flights To Sialkot, Pakistan From UK",
    date: "Thu 16 Mar - Mon 03 Apr",
    price: "£410*",
  },
];
function BookNow() {
  const navigation = useNavigate();
  return (
    <div >
      <h1 className="book_now_heading"> Today's Lowest Fare</h1>
        <div className="background_card">
         
            {dataCheapFlights.map((data, index) => {
              return (
                  <div
                    onClick={() => navigation(`/${data.flightUrl}`)}
                    className="book_card_items_home"
                  >
                    <div className="book_Card_img"></div>
                    <h6 style={{fontFamily:"bitter"}}>{data.title1}</h6>
                    <span className="card_date">{data.date}</span>
                    <div className="from_box">
                      <div className="from_line" />
                      <span>From</span>
                      <div className="from_line" />
                    </div>
                    <span className="book_Card_price">{data.price}</span>
                    <span className="book_Card_order">Book Now</span>
                  </div>
               
              );
            })}
        </div>
    </div>
  );
}

export default BookNow;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePhone } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import "./header2.css";
const Header2 = () => {
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    var btn = document.querySelector(".navicon");
    var aaa = document.querySelector(".myNavbar ");
    function toggleFunc() {
      return aaa.classList.toggle("show");
    }
    btn.addEventListener("click", toggleFunc);
    // Sidenav li open close
    var navUl = [].slice.call(document.querySelectorAll(".navbar-nav > li"));
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }
    function checkLi(current) {
      navUl.forEach((el) => el.classList.remove("open"));
      current.classList.add("open");
    }
  }, []);

  return (
    <header style={{ fontFamily: "lora" }}>
      <div className="sticky-header navbar-expand-lg main-bar-wraper ">
        <div
          className="top_header_container"
          style={{ borderBottom: "1px solid lightgray", marginBottom: 9 }}
        >
          <div className="working_header">
            <div>
              <a
                style={{ textDecoration: "none", color: "#2293fa" }}
                className="top_header_child_text"
                href="mailto:support@flightsflair.com"
              >
                <HiOutlineMail style={{ marginBottom: -3, marginRight: 3 }} />
                support@flightsflair.com
              </a>

              <a
                className="top_header_child_text"
                style={{
                  textDecoration: "none",
                  color: "#2293fa",
                  marginLeft: 50,
                }}
                href="tel:0208-004-4475"
              >
                <AiOutlinePhone style={{ marginBottom: -3, marginRight: 3 }} />
                0208-638-6789
              </a>
            </div>
            <div style={{ color: "#2293fa" }}>
              Lines Open 24 Hours 7 Days A Week
            </div>
          </div>
        </div>

        <div className="main-bar clearfix onepage" >
          <div className="container-fluid clearfix main_header_container ">
            <div className="logo-header mostion">
              <Link to={"/"}>
                <img
                  style={{ width: 140, height: 90 }}
                  className="flight_logo"
                  src={require("../../asstes/images/flightsLogo.webp")}
                  alt="flightsflair"
                />
              </Link>
            </div>
            <button
              onClick={() => handleOpen()}
              style={{ position: "absolute", top: -28, right: 10, zIndex: 120 }}
              className="navbar-toggler collapsed navicon justify-content-end"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div
          
              className="header-nav navbar-collapse collapse navbar myNavbar justify-content-center mobile_header"
              id="navbarNavDropdown"
            >
              <ul className="nav navbar-nav" style={{}}>
                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"/"}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"/whatWeDo"}
                  >
                    What We Do
                  </Link>
                </li>
                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"/bookingTerms"}
                  >
                    Booking Terms
                  </Link>
                </li>
                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"/blog"}
                  >
                    Blogs
                  </Link>
                </li>
                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"#"}
                  >
                     <div className="arrow_down">   Flights <IoIosArrowDown fontSize={15} alignItems="center"/></div>

                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to={"/cheapflightstokarachi"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Karachi, Pakistan From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstolahore"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Lahore, Pakistan From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstoislamabad"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Islamabad, Pakistan From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstopeshawar"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Peshawar, Pakistan From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstosialkot"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Sialkot, Pakistan From UK
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"#"}
                  >
                     <div className="arrow_down">   Flights to Africa <IoIosArrowDown fontSize={15} alignItems="center"/></div>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to={"/cheapflightstonairobi"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Nairobi, Kenya From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstoaccra"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Accra, Ghana From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstolagos"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Lagos, Nigeria From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstocapetown"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Cape Town, South Africa From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstojohannesburg"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Johannesburg, South Africa From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstobulawayo"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Bulawayo , Zimbabwe From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstoharare"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Harare , Zimbabwe From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstovictoria"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Victoria Falls , Zimbabwe From UK
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"#"}
                  >
                     <div className="arrow_down">   Flights to Canada <IoIosArrowDown fontSize={15} alignItems="center"/></div>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to={"/cheapflightstotoronto"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Toronto, Canada From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstovancouver"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Vancouver , Canada From UK
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"#"}
                  >
               <div className="arrow_down">  Flights to Australia <IoIosArrowDown fontSize={15} alignItems="center"/></div>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to={"/cheapflightstobrisbane"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Brisbane, Australia From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstomelbourne"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Melbourne, Australia From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstoperth"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Perth, Australia From UK
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cheapflightstosydney"}
                        style={{ fontSize: 12 }}
                      >
                        Cheap Flights To Sydney, Australia From UK
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    className="header_text"
                    style={{ fontSize: 18 }}
                    to={"/contact"}
                  >
                    {" "}
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="web_hidden">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ color: "#2293fa" }}> 0208-638-6789</div>
              <div>Lines Open 24 Hours 7 Days A Week</div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header2;

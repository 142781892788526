import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const Loader = () => {
  return (
    <Box
      sx={{ display: "flex", gap: 2, alignItems: "center", flexWrap: "wrap",justifyContent:"center" ,height:"80vh"}}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;

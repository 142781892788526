import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import {
  AiFillGoogleCircle,
  AiFillInstagram,
  AiFillPhone,
} from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";

import "./footer.css";

//Images
const googleLink =
  "https://www.google.com/search?q=Flightsflairltd&stick=H4sIAAAAAAAA_-NgU1I1qDAxT7FINE8xTzE0NbBMNEqzMqgwTjM2TUw0STQ1MTFKtEhJXsTK75aTmZ5RUpyWk5hZlFOSAgDC-zg5OwAAAA&hl=en&mat=CbyKZUKgYW_WElcBpsFAC1oOBRwcddj3MxRrtcG4vtMY9N4IGkdBpu7mv2vpTFvgr2nq7ptT4lCnhybJB_YNvHiBGHDvbxDDND8OXMiSLBFjIB1neQq1sa8_01ZcoXrtBhU&authuser=0#scso=_iNN5ZM7fOJyZhbIPivS3WA_67:27";

var bg3 = require("./../../images/background/bg6.jpg");

const Footer = () => {
  return (
    <footer className="site-footer style1">
      <div
        className="footer-top"
        style={{
          backgroundImage: "url(" + bg3 + ")",
          backgroundSize: "cover",
        }}
      >
        <div className="footer_container">
          <div className="col-lg-5 col-md-6 col-sm-5 footer-col-4">
            <div className="widget widget_getintuch">
              <h6 className="m-b15 h6">About Us</h6>
              <div className="dlab-separator bg-white"></div>
              <ul className="info-contact">
                <li>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <ImLocation2 style={{ marginRight: 3 }} /> United Kingdom
                  </span>
                </li>
                <li>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <AiFillPhone style={{ marginRight: 3 }} />
                    <a
                      className="fa "
                      style={{ textDecoration: "none" }}
                      href="tel:0208-004-4475"
                    >
                      0208-638-6789
                    </a>
                    <br />
                  </span>
                </li>
                <li>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <HiOutlineMail style={{ marginRight: 3 }} />
                    <a
                      className="fa "
                      style={{ textDecoration: "none" }}
                      href="tel:0208-004-4475"
                    >
                      support@flightsflair.com
                    </a>
                    <br />
                  </span>
                </li>
              </ul>
            </div>
            <ul className="list-inline">
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="site-button facebook sharp"
                  to={googleLink}

                >
                  <AiFillGoogleCircle
                    style={{ marginBottom: -5, fontSize: 20 }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={"https://www.facebook.com/profile.php?id=100089312886036"}

                  className="site-button google sharp"
                >
                  <FaFacebook style={{ marginBottom: -4, fontSize: 18 }} />
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={
                    "https://instagram.com/flightsflair_?igshid=OGQ5ZDc2ODk2ZA=="
                  }
                  className="site-button instagram sharp"
                >
                  <AiFillInstagram style={{ marginBottom: -5, fontSize: 20 }} />
                </Link>
              </li>
            </ul>
          </div>
          <div style={{marginTop:1}} className="col-lg-3 col-md-6 col-sm-5 footer-col-4">
            <div className="">
              <h6 className="m-b15 h6 ">Flights</h6>
              <div className="dlab-separator bg-white"></div>
              <ul className="info-contact">
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstokarachi"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Karachi, Pakistan From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstolahore"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Lahore, Pakistan From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstoislamabad"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Islamabad, Pakistan From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstopeshawar"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Peshawar, Pakistan From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstosialkot"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Sialkot, Pakistan From UK
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-5 footer-col-4">
            <div className=" ">
              <h6 className="m-b15 h6 ">Flights to Africa</h6>
              <div className="dlab-separator bg-white"></div>
              <ul className="info-contact">
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstonairobi"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Nairobi, Kenya From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link to={"/cheapflightstoaccra"} className="dez-page-text">
                      Cheap Flights To Accra, Ghana From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <Link to={"/cheapflightstolagos"} className="dez-page-text">
                    Cheap Flights To Lagos, Nigeria From UK
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/cheapflightstocapetown"}
                    className="dez-page-text"
                  >
                    Cheap Flights To Cape Town, South Africa From UK
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/cheapflightstojohannesburg"}
                    className="dez-page-text"
                  >
                    Cheap Flights To Johannesburg, South Africa From UK
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/cheapflightstobulawayo"}
                    className="dez-page-text"
                  >
                    Cheap Flights To Bulawayo , Zimbabwe From UK
                  </Link>
                </li>
                <li>
                  <Link to={"/cheapflightstoharare"} className="dez-page-text">
                    Cheap Flights To Harare , Zimbabwe From UK
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/cheapflightstovictoria"}
                    className="dez-page-text"
                  >
                    Cheap Flights To Victoria Falls , Zimbabwe From UK
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_container">
          <div className="col-lg-5 col-md-6 col-sm-5 footer-col-4 hide_mobile">
            <div style={{ color: "white", fontSize: 16 }}>
              FlightsFlair LTD is a sub agent of ATOL with financially protected
              flights and holidays on the website. ATOL protection may not cover
              all holidays and travel services listed on this website. Check
              with us for applicable protection. Visit
              www.atol.org.uk/ATOLCertificate for more information on financial
              protection and the ATOL Certificate. See our booking conditions
              for details.
            </div>
          </div>

          <div  style={{marginTop:1}}  className="col-lg-3 col-md-6 col-sm-5 footer-col-4">
            <div className=" ">
              <h6 className="m-b15 h6 ">Flight to Australia</h6>
              <div className="dlab-separator bg-white"></div>
              <ul className="info-contact">
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstobrisbane"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Brisbane, Australia From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstomelbourne"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Melbourne, Australia From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link to={"/cheapflightstoperth"} className="dez-page-text">
                      Cheap Flights To Perth, Australia From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstosydney"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Sydney, Australia From UK
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-5 footer-col-4">
            <div className=" ">
              <h6 className="m-b15 h6 ">Flights to Canada</h6>
              <div className="dlab-separator bg-white"></div>
              <ul className="info-contact">
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstotoronto"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Toronto, Canada From UK
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    <Link
                      to={"/cheapflightstovancouver"}
                      className="dez-page-text"
                    >
                      Cheap Flights To Vancouver , Canada From UK
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-5 footer-col-4 hide_web">
            <div style={{ color: "white", fontSize: 16 }}>
              FlightsFlair LTD is a sub agent of ATOL with financially protected
              flights and holidays on the website. ATOL protection may not cover
              all holidays and travel services listed on this website. Check
              with us for applicable protection. Visit
              www.atol.org.uk/ATOLCertificate for more information on financial
              protection and the ATOL Certificate. See our booking conditions
              for details.
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-right">
              {" "}
              <span>Copyright © 2022 flightsflair</span>{" "}
            </div>
            <div className="col-lg-6 col-md-6 text-right "></div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Layout/ScrollToTop";
import Homepage2 from "./Pages/Homepage2";
import InstantFlight from "./Layout/InstantFlights";
import Header2 from "./Layout/Header2";
import Footer from "./Layout/Footer";
import Loader from "./components/Loader";
import Error404 from "./Pages/Error";
const BlogClassic = lazy(() => import('./Pages/BlogClassic'));
const WhatWeDo = lazy(() => import('./WhatWeDo'));
const BookingTerms = lazy(() => import('./BookingTerms'));
const Contact = lazy(() => import('./Pages/Contacts'));
const BlogDetails = lazy(() => import('./Pages/BlogDetails'));
const FlightKarachi = React.lazy(() => import('../CheapFlights/FlightKarachi'));
const FlightLahore = React.lazy(() => import('../CheapFlights/FlightLahore'));
const FlightIslamabad = React.lazy(() => import('../CheapFlights/FlightIslamabad'));
const FlightPeshawar = React.lazy(() => import('../CheapFlights/FlightPeshawar'));
const FlightSialkot = React.lazy(() => import('../CheapFlights/FlightSialkot'));
const FlightAccra = React.lazy(() => import('../CheapFlights/FlightAccra'));
const FlightBrisbane = React.lazy(() => import('../CheapFlights/FlightBrisbane'));
const FlightBulawayo = React.lazy(() => import('../CheapFlights/FlightBulawayo'));
const FlightCapeTown = React.lazy(() => import('../CheapFlights/FlightCapeTown'));
const FlightHarare = React.lazy(() => import('../CheapFlights/FlightHarare'));
const FlightJohannesburg = React.lazy(() => import('../CheapFlights/FlightJohannesburg'));
const FlightLagos = React.lazy(() => import('../CheapFlights/FlightLagos'));
const FlightMelbourne = React.lazy(() => import('../CheapFlights/FlightMelbourne'));
const FlightNairobi = React.lazy(() => import('../CheapFlights/FlightNairobi'));
const FlightPerth = React.lazy(() => import('../CheapFlights/FlightPerth'));
const FlightSydney = React.lazy(() => import('../CheapFlights/FlightSydney'));
const FlightToronto = React.lazy(() => import('../CheapFlights/FlightToronto'));
const FlightVancouver = React.lazy(() => import('../CheapFlights/FlightVancouver'));
const FlightVictoria = React.lazy(() => import('../CheapFlights/FlightVictoria'));
const Markup = () => {
  return (
    <Router>
      <Header2 />
      <Routes>
        <Route path="/" element={<Homepage2 />} />
        <Route path="/error" element={<Error404 />} />
      </Routes>
      <Suspense fallback={<Loader/>}>
        <Routes>
        <Route path="/cheapflightstoaccra" element={<FlightAccra />} />
        <Route path="/cheapflightstobrisbane" element={<FlightBrisbane />} />
        <Route path="/cheapflightstobulawayo" element={<FlightBulawayo />} />
        <Route path="/cheapflightstocapetown" element={<FlightCapeTown />} />
        <Route path="/cheapflightstoharare" element={<FlightHarare />} />
        <Route
          path="/cheapflightstojohannesburg"
          element={<FlightJohannesburg />}
        />
        <Route path="/cheapflightstolagos" element={<FlightLagos />} />
        <Route path="/cheapflightstomelbourne" element={<FlightMelbourne />} />
        <Route path="/cheapflightstonairobi" element={<FlightNairobi />} />
        <Route path="/cheapflightstoperth" element={<FlightPerth />} />
        <Route path="/cheapflightstosydney" element={<FlightSydney />} />
        <Route path="/cheapflightstotoronto" element={<FlightToronto />} />
        <Route path="/cheapflightstovancouver" element={<FlightVancouver />} />
        <Route path="/cheapflightstovictoria" element={<FlightVictoria />} />
        <Route path="/cheapflightstokarachi" element={<FlightKarachi />} />
        <Route path="/cheapflightstolahore" element={<FlightLahore />} />
        <Route path="/cheapflightstoislamabad" element={<FlightIslamabad />} />
        <Route path="/cheapflightstopeshawar" element={<FlightPeshawar />} />
        <Route path="/cheapflightstosialkot" element={<FlightSialkot />} />
        </Routes>
      </Suspense>
      <Suspense fallback={<Loader/>}>
        <Routes>
        <Route path="/blog" element={<BlogClassic />} />
        <Route path="/whatWeDo" element={<WhatWeDo />} />
        <Route path="/bookingTerms" element={<BookingTerms />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogdetails" element={<BlogDetails />} />
        </Routes>
      </Suspense>
      <ScrollToTop />
      <InstantFlight />
      <Footer />
    </Router>
  );
};

export default Markup;
